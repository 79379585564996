@media screen and (max-width: 768px) {
  /* Navbar */
  #navbar {
    margin: calc(1 * 29px) auto;
  }

  .navbar-brand {
    margin-left: calc(1 * 29px);
  }

  .navbar-button {
    margin-right: calc(1 * 34px);
  }
  /* Navbar */

  /* TopSection */
  #top-section {
    min-height: 600px;
    background-position: center 0;
    padding: 0 calc(1 * 29px);
  }

  #top-section.T1 {
    background-position: 40% 0;
  }

  #top-section.T2 {
    background-position: center 0;
  }

  #top-section.T3 {
    background-position: 60% 0;
  }

  #top-section.T4 {
    background-position: 45% 0;
  }

  .top-section-content {
    margin-top: calc(1 * 99px);
    height: 350px;
  }

  .top-section-heading {
    width: calc(1 * 287px);
    margin-bottom: calc(1 * 9px);

    font-size: calc(1 * 40.2px);
    line-height: calc(1 * 49px);
  }

  .top-section-subheading {
    width: calc(1 * 262px);
    margin-bottom: calc(1 * 26px);

    font-size: calc(1.2 * 14px);
    line-height: calc(1 * 23px);
  }
  /* TopSection */

  /* Button */
  .button {
    border-radius: 3.41px;
    border: 1px solid #000000;

    width: calc(1 * 139px);
    height: calc(1 * 45.39px);

    font-size: calc(1 * 12px);
    line-height: calc(1 * 11px);
    letter-spacing: 0em;
  }

  .button.small {
    font-size: calc(1 * 12px);
    line-height: calc(1 * 11px);

    width: calc(1 * 116px);
    height: calc(1 * 31px);
  }

  .button.large {
    border-radius: 6px;
    font-size: calc(1 * 14.6px);
    line-height: calc(1 * 13px);

    width: 100%;
    height: calc(1 * 65px);
  }

  .button-arrow {
    display: none;
  }
  /* Button */

  /* Carousel */
  .carousel-indicators {
    /* margin: calc(1 * 20.6px) 0 0 -3px; */
    top: 375px;
    left: 32px;
  }

  .carousel-indicators [data-bs-target] {
    width: 8.15px;
    height: 8.15px;
    margin-right: calc(1 * 6.7px);
  }
  /* Carousel */

  /* User Agreement */
  .agreementDiv {
    padding-top: 100px;
  }
  /* User Agreement */

  /* middleSection */
  .middle-section {
    padding: 0;
  }
  /* middleSection */

  /* Join */
  .join-waitlist {
    padding: calc(1 * 21px) calc(1 * 29px);
  }

  .join-waitlist-text {
    /* min-width: calc(1 * 97px); */
    min-width: fit-content;
    height: calc(1 * 17px);

    font-size: calc(1.2 * 14px);
    line-height: calc(1 * 17px);
  }

  .join-waitlist-line {
    margin-left: calc(1 * 12px);
  }
  /* Join */

  /* Waitlist */
  .waitlist-section {
    padding: 0 calc(1 * 29px);
    flex-direction: column;
    position: relative;
    align-items: flex-start;
  }

  .waitlist-section-image img {
    width: calc(1 * 208px);
    max-width: calc(1 * 280px);
    margin-top: 0;
    margin-bottom: 13.23px;
  }

  .waitlist-section-right {
    align-items: flex-start;
    margin-left: 0;
  }

  .waitlist-section-heading {
    min-width: calc(0.9 * 272px);
    max-width: calc(1 * 545px);

    margin-bottom: calc(1 * 9px);

    font-size: calc(1 * 40.3px);
    line-height: calc(1 * 49px);
  }

  .waitlist-section-subheading {
    min-width: calc(0.9 * 326px);
    max-width: none;

    margin-bottom: calc(1 * 20px);

    font-size: calc(1.2 * 14px);
    line-height: calc(1 * 23px);
  }

  .waitlist-section-email-button {
    display: flex;
    flex-direction: column;
    row-gap: calc(1 * 13px);
    width: 100%;
  }
  /* Waitlist */

  /* Input */
  .input {
    width: 100%;
    max-width: none;
    height: calc(1 * 51px);
    padding: 0 calc(1 * 22px);

    font-size: calc(1.2 * 14px);
    line-height: calc(1 * 17px);
  }
  /* Input */

  /* Survey */
  .survey-section {
    padding: calc(1 * 29px) calc(1 * 29px) 60px calc(1 * 29px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .survey-section-image img {
    width: calc(1 * 257px);
    margin-bottom: 13.99px;
  }

  @media screen and (max-width: 300px) {
    .survey-section-image img {
      width: calc(0.8 * 257px);
    }
  }

  .survey-section-right {
    margin-left: 0;
  }

  .survey-section-heading {
    min-width: calc(0.9 * 272px);
    max-width: calc(1 * 545px);

    margin-bottom: calc(1 * 14px);

    font-size: calc(1 * 40.3px);
    line-height: calc(1 * 49px);
  }

  .survey-section-subheading {
    max-width: none;
    margin-bottom: calc(1 * 21px);

    font-size: calc(1.2 * 14px);
    line-height: calc(1 * 23px);
  }

  .survey-section-button {
    width: 100%;
  }
  /* Survey */

  /* Success */
  .success-section {
    padding: calc(1 * 29px) calc(1 * 29px) 43px calc(1 * 29px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .success-section-image {
    width: fit-content;
    text-align: left;
    margin-top: 0;
  }

  .success-section-image img {
    width: calc(1 * 202px);
    /* margin-right: 0; */
  }

  .success-section-middle-wrapper {
    width: 100%;
  }

  .success-section-middle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0;
  }

  /* .success-section-right {
    display: none;
  } */

  .success-section-heading {
    min-width: calc(0.9 * 272px);
    max-width: calc(1 * 545px);

    margin-bottom: calc(1 * 14px);

    font-size: calc(1 * 40.3px);
    line-height: calc(1 * 49px);
  }

  @media screen and (max-width: 300px) {
    .success-section-image img {
      width: calc(0.8 * 202px);
    }

    .success-section-heading {
      min-width: calc(0.8 * 272px);
      font-size: calc(0.8 * 40.3px);
    }
  }

  .success-section-subheading {
    max-width: none;
    margin-bottom: calc(1 * 21px);

    font-size: calc(1.2 * 14px);
    line-height: calc(1 * 23px);
  }

  .success-section-button {
    width: 100%;
  }
  /* Success */

  /* Footer */
  #footer-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: calc(1 * 35px) calc(1 * 29px) calc(1 * 100px) calc(1 * 29px);
  }

  .footer-logo {
    width: 100%;
    margin-right: 0;
  }

  .footer-text {
    width: 100%;

    font-size: calc(1.2 * 14px);
    line-height: calc(1 * 23px);
  }

  .footer-text-info {
    width: 100%;
    max-width: none;
    margin-top: 19.4px;
  }

  .footer-text-terms {
    margin-top: calc(1 * 32px);

    font-size: calc(1.2 * 14px);
    line-height: calc(1 * 17px);
    flex-direction: column;
    row-gap: 13px;
  }
  /* Footer */
}
